import { motion } from "framer-motion";
import React from "react";
import { useLocale } from "./LocaleProvider";
import NavLink from "./NavLink";

const NavCompany: React.FC = () => {
	const l = useLocale().keys;
	return (
		<motion.div className="flex-column flex max-w-lg bg-white">
			<div className="relative flex-1 p-4 ">
				<NavLink
					icon="briefcase"
					title={l.linkAboutTitle}
					description={l.linkAboutDescription}
					href={l.linkAboutUrl}
				/>
				<NavLink
					icon="shieldCheck"
					title={l.linkSecurityTitle}
					description={l.linkSecurityDescription}
					href={l.linkSecurityUrl}
				/>
				<NavLink
					icon="users"
					title={l.linkPartnershipsTitle}
					description={l.linkPartnershipsDescription}
					href={l.linkPartnershipsUrl}
				/>
			</div>
			<div className="my-8 border-l" />
			<div className="flex-1 p-4">
				<NavLink
					title={l.linkWhyChooseTitle}
					description={l.linkWhyChooseDescription}
					href={l.linkWhyChooseUrl}
				/>

				<NavLink
					title={l.linkBrochureTitle}
					description={l.linkBrochureDescription}
					href={l.linkBrochureUrl}
				/>

				<div className="rounded-lg border border-green-100 bg-green-50 p-2 text-xs">
					<NavLink
						small
						icon="star"
						title={l.linkCustomerReviewsTitle}
						description={l.linkCustomerReviewsDescription}
						href={l.linkCustomerReviewsUrl}
					/>
				</div>
			</div>
		</motion.div>
	);
};

export default NavCompany;
