import React, { useEffect, useState } from "react";
import { render } from "react-dom";
import LocaleProvider, { Lang } from "./components/LocaleProvider";
import Nav from "./components/Nav";

import "tailwindcss/tailwind.css";

declare global {
	interface Window {
		onLangChanged: (l: Lang) => void;
	}
}

const version = String(import.meta.env.VITE_PKG_VERSION);
const environment = String(import.meta.env.MODE);
console.info(`v${version} (${environment})`);

const App: React.FC = () => {
	const [lang, setLang] = useState<Lang>("en");
	useEffect(() => {
		if (document.cookie.match("wglang=pt")) {
			setLang("pt");
		}
		const onLangchanged = ({ data }: MessageEvent) => {
			if (data.lang === "pt" || data.lang === "en") {
				setLang(data.lang);
			}
		};
		addEventListener("message", onLangchanged);
		return () => removeEventListener("message", onLangchanged);
	}, []);
	return (
		<LocaleProvider lang={lang}>
			<Nav className={"lg:p-2"} />
		</LocaleProvider>
	);
};

render(<App />, document.getElementById("app-nav"));
