import React, { createContext, useContext, useEffect, useState } from "react";
import localeEn from "../../assets/nav-en.json";
import localePt from "../../assets/nav-pt.json";

export type Locale = typeof localeEn;
export type Lang = "en" | "pt";

export const localeContext = createContext<Locale>(localeEn);

type UseLocale = () => {
	keys: Locale;
	getString: (key: keyof Locale) => string | undefined;
};
export const useLocale: UseLocale = () => {
	const c = useContext(localeContext);
	return {
		keys: c,
		getString: (key: keyof Locale) => c[key],
	};
};

const loadLocale = (lang: Lang): Promise<Locale> => {
	if (lang === "en") {
		return Promise.resolve(localeEn);
	}
	if (lang === "pt") {
		return Promise.resolve(localePt);
	}
	return Promise.resolve({} as Locale);
};

const LocaleProvider: React.FC<{ lang?: Lang }> = ({
	lang = "en",
	children,
}) => {
	const [locale, setLocale] = useState<Locale>({} as Locale);
	useEffect(() => {
		loadLocale(lang).then(setLocale);
	}, [lang]);

	if (!locale) {
		return null;
	}
	return <localeContext.Provider value={locale} children={children} />;
};

export default LocaleProvider;
