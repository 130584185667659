import { motion } from "framer-motion";
import React from "react";
import Icon, { IconName } from "../lib/icons";
import { useLocale } from "./LocaleProvider";
import NavLink from "./NavLink";

type SocialLinkProps = {
	url: string;
	icon: IconName;
};
const SocialLink: React.FC<SocialLinkProps> = ({ url, icon }) => {
	return (
		<a
			className="mr-3 inline-block h-5 w-5 text-gray-400 transition-colors hover:text-blue-500"
			href={url}
			target="_blank"
			rel="noReferrer"
		>
			<Icon className="md:h-4" name={icon} />
		</a>
	);
};

const NavNews: React.FC = () => {
	const l = useLocale().keys;
	const motionFooter = {
		hidden: { opacity: 0, y: 100 },
		visible: {
			opacity: 1,
			y: 0,
			transition: {
				delay: 0.15,
				type: "spring",
				duration: 0.3,
				bounce: 0.25,
			},
		},
	};
	return (
		<>
			<motion.div className="flex-column flex max-w-lg bg-white">
				<div className="relative flex-1 p-4">
					<NavLink
						icon="newspaper"
						title={l.linkBlogTitle}
						description={l.linkBlogDescription}
						href={l.linkBlogUrl}
					/>
				</div>
				<div className="my-4 border-l" />
				<div className="flex-1 p-4">
					<NavLink
						icon="mail"
						title={l.linkDailyReportTitle}
						description={l.linkDailyReportDescription2}
						href={l.linkDailyReportUrl}
					/>
				</div>
			</motion.div>
			<motion.div
				className="flex-column flex max-w-lg items-center bg-blue-50 text-xs"
				variants={motionFooter}
			>
				<div className="p-4">
					<h3 className="text-xs font-medium">
						{l.linkNewsSocialTitle}
					</h3>
					<p className="text-xs text-gray-500">
						{l.linkNewsSocialDescription}
					</p>
				</div>
				<div className="ml-auto flex p-4">
					<SocialLink
						icon="facebook"
						url="https://facebook.com/worldwidecurrencies"
					/>
					<SocialLink
						icon="twitter"
						url="https://twitter.com/wwidecurrencies"
					/>
					<SocialLink
						icon="linkedin"
						url="https://linkedin.com/company/worldwide-currencies"
					/>
					<SocialLink
						icon="instagram"
						url="https://instagram.com/worldwidecurrencies"
					/>
					<SocialLink
						icon="youtube"
						url="https://youtube.com/channel/UCNjBbfbl9LtjRtDw0GCBBXg"
					/>
				</div>
			</motion.div>
		</>
	);
};

export default NavNews;
