import cx from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import React, { useCallback, useRef, useState } from "react";
import { useLocale } from "./LocaleProvider";
import Logo from "./Logo";
import NavCompany from "./NavCompany";
import NavHelp from "./NavHelp";
import NavJoin from "./NavJoin";
import NavNews from "./NavNews";
import NavPopover from "./NavPopover";
import NavServices from "./NavServices";

type Props = React.HTMLAttributes<HTMLElement>;
const NavDesktop: React.FC<Props> = ({ className }) => {
	const l = useLocale().keys;
	const navRef = useRef<HTMLElement>(null);
	const [target, setTarget] = useState<string>();
	const [menuOffset, setMenuOffset] = useState({ x: 0, y: 0 });

	const isService = target === l.tabServicesTitle;
	const isCompany = target === l.tabCompanyTitle;
	const isNews = target === l.tabNewsTitle;
	const isHelp = target === l.tabHelpTitle;
	const isJoin = target === l.tabJoinTitle;

	const updateTarget = useCallback<
		(e: React.PointerEvent<HTMLElement>) => void
	>((e) => {
		const t = e.currentTarget;
		const label = e.currentTarget.getAttribute("aria-label") || undefined;
		//const x = t.offsetLeft + t.offsetWidth / 2;
		const box = t.getBoundingClientRect();
		const x = box.x + box.width / 2;
		const y = navRef.current
			? navRef.current.offsetTop + navRef.current.offsetHeight
			: 0;

		setMenuOffset({ x, y });
		setTarget(label);
	}, []);
	return (
		<nav
			ref={navRef}
			className={className}
			onMouseLeave={() => setTarget(undefined)}
		>
			<div className={styles.container}>
				<a href="/">
					<Logo className="logo mt-1 w-28 lg:w-36" />
				</a>
				<div className="mx-auto flex">
					<span
						aria-label={l.tabServicesTitle}
						className={cx(
							styles.navItem,
							isService && "text-accent"
						)}
						onMouseEnter={updateTarget}
						children={l.tabServicesTitle}
					/>
					<span
						aria-label={l.tabCompanyTitle}
						className={cx(
							styles.navItem,
							isCompany && "text-accent"
						)}
						onMouseEnter={updateTarget}
						children={l.tabCompanyTitle}
					/>
					<span
						aria-label={l.tabNewsTitle}
						className={cx(styles.navItem, isNews && "text-accent")}
						onMouseEnter={updateTarget}
						children={l.tabNewsTitle}
					/>
					<span
						aria-label={l.tabHelpTitle}
						className={cx(styles.navItem, isHelp && "text-accent")}
						onMouseEnter={updateTarget}
						children={l.tabHelpTitle}
					/>
					<a
						aria-label={l.tabContactTitle}
						className={cx(styles.navItem, "hover:text-accent")}
						href="/contact"
						children={l.tabContactTitle}
					/>
				</div>
				<span
					aria-label={l.tabJoinTitle}
					className={cx(styles.button)}
					onMouseEnter={updateTarget}
					children={l.tabJoinTitle}
				/>
			</div>
			<AnimatePresence>
				{target !== undefined && (
					<>
						<motion.div
							initial={{ opacity: 0 }}
							exit={{ opacity: 0 }}
							animate={{ opacity: 0.6 }}
							className={styles.overlay}
						/>
						<NavPopover
							offset={menuOffset}
							className={cx(
								"mt-3",
								isJoin && "bg-accent text-accent"
							)}
						>
							{isService && <NavServices />}
							{isCompany && <NavCompany />}
							{isNews && <NavNews />}
							{isHelp && <NavHelp />}
							{isJoin && <NavJoin />}
						</NavPopover>
					</>
				)}
			</AnimatePresence>
		</nav>
	);
};

const styles = {
	container: cx(
		"relative z-10",
		"flex justify-center items-center",
		"mx-10 lg:p-2 lg:text-base xl:container xl:mx-auto",
		//"border-b border-accent",
		"font-semibold text-sm cursor-default"
	),
	navItem: cx("py-6 px-5 lg:px-8 transition-colors"),
	button: cx(
		"px-6 py-2 text-white rounded-full shadow",
		"bg-gradient-to-b from-blue-500 to-blue-600"
	),
	overlay: cx(
		"fixed top-0 left-0 right-0 bottom-0 z-0",
		"pointer-events-none",
		"bg-gray-500"
	),
};

export default NavDesktop;
