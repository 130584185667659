import cx from "classnames";
import { motion, Spring, Variants } from "framer-motion";
import React, { useRef } from "react";

type Props = React.HTMLAttributes<HTMLElement> & {
	offset: { x: number; y: number };
	align?: string;
};

const NavPopover: React.FC<Props> = ({ offset, className, children }) => {
	const content = useRef<HTMLDivElement>(null);

	const adjustX = (x: number): number => {
		if (!content.current) {
			return 0;
		}
		const bodyWidth = document.documentElement.offsetWidth;
		const { width } = content.current?.getBoundingClientRect();
		let newX = x - width / 2;
		if (newX < 0) {
			newX = x;
		}
		if (newX + width > bodyWidth) {
			newX = bodyWidth - width - 60;
		}
		return newX;
	};

	const transition: Spring = {
		type: "spring",
		//duration: 0.3,
		bounce: 0,
		damping: 11,
		mass: 0.5,
		stiffness: 100,
	};

	const motionPopover: Variants = {
		hidden: ({ x, y }) => ({ x: adjustX(x) - 100, y, opacity: 0 }),
		visible: ({ x, y }) => ({ x: adjustX(x), y, opacity: 1 }),
	};

	const motionNip: Variants = {
		hidden: ({ x, y }) => ({ x: x - 10, y, opacity: 0 }),
		visible: ({ x, y }) => ({ x, y, opacity: 1 }),
	};

	return (
		<motion.div className="text-white">
			<motion.svg
				className={cx(styles.nip, className)}
				width="20"
				height="10"
				fill="white"
				initial="hidden"
				animate="visible"
				exit="hidden"
				transition={transition}
				variants={motionNip}
				custom={offset}
			>
				<polygon points="10,0 0,10 20,10" />
			</motion.svg>
			<motion.div
				className={styles.container}
				initial="hidden"
				animate="visible"
				exit="hidden"
				transition={transition}
				variants={motionPopover}
				custom={offset}
			>
				<motion.div className={cx(styles.popover, className)} layout>
					<motion.div ref={content} layout="position">
						{children}
					</motion.div>
				</motion.div>
			</motion.div>
		</motion.div>
	);
};

const styles = {
	container: cx("fixed top-0 left-0 z-10"),
	popover: cx(
		"font-normal text-black text-sm",
		"rounded shadow-md",
		"overflow-hidden",
		"color-white",
		"bg-white"
	),
	nip: cx("fixed -top-2 left-0 -ml-2 sm:bg-transparent fill-current"),
};

export default NavPopover;
