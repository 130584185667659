import React, { useEffect, useState } from "react";
import NavDesktop from "./NavDesktop";
import NavMobile from "./NavMobile";

const breakpoint = "(max-width:768px)";

const Nav: React.FC<React.HTMLAttributes<HTMLElement>> = ({ className }) => {
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const onResize = (): void => {
			setIsMobile(matchMedia(breakpoint).matches);
		};
		addEventListener("resize", onResize);
		onResize();
		return () => {
			removeEventListener("resize", onResize);
		};
	}, [isMobile]);

	return isMobile ? (
		<NavMobile className={className} />
	) : (
		<NavDesktop className={className} />
	);
};

export default Nav;
