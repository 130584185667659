import cx from "classnames";
import { motion } from "framer-motion";
import React, { useState } from "react";
import Icon from "../lib/icons";
import { useLocale } from "./LocaleProvider";
import Logo from "./Logo";
import NavLink from "./NavLink";

const NavMenu: React.FC = () => {
	const { keys: _ } = useLocale();
	const motionContainer = {
		hidden: { opacity: 0 },
		visible: { opacity: 1 },
	};
	return (
		<motion.div className="text-gray-600" variants={motionContainer}>
			<h3 className="my-4 text-xs uppercase">{_.tabServicesTitle}</h3>

			<div className="flex">
				<div className="flex-1">
					<NavLink
						small
						title={_.linkPrivateTitle}
						href={_.linkPrivateUrl}
						icon="home"
					/>
					<NavLink
						small
						icon="briefcase"
						title={_.linkCorporateTitle}
						href={_.linkCorporateUrl}
					/>
					<NavLink
						small
						icon="library"
						title={_.linkInstitutionalTitle}
						href={_.linkInstitutionalUrl}
					/>
				</div>
				<div className="flex-1">
					<NavLink
						small
						title={_.linkMobileAppTitle}
						href={_.linkMobileAppUrl}
					/>
					<NavLink
						small
						title={_.linkDailyReportTitle}
						href={_.linkDailyReportUrl}
					/>
					<NavLink
						small
						title={_.linkFreeQuoteTitle}
						href={_.linkFreeQuoteUrl}
					/>
					<NavLink
						small
						title={_.linkTradewaveTitle}
						href={_.linkTradewaveUrl}
					/>
				</div>
			</div>

			<h3 className="my-4 text-xs uppercase">{_.tabCompanyTitle}</h3>

			<div className="flex">
				<div className="flex-1">
					<NavLink
						small
						icon="briefcase"
						title={_.linkAboutTitle}
						href={_.linkAboutUrl}
					/>
					<NavLink
						small
						icon="shieldCheck"
						title={_.linkSecurityTitle}
						href={_.linkSecurityUrl}
					/>
					<NavLink
						small
						icon="users"
						title={_.linkPartnershipsTitle}
						href={_.linkPartnershipsUrl}
					/>
				</div>
				<div className="flex-1">
					<NavLink
						small
						title={_.linkHowItWorksTitle}
						href={_.linkHowItWorksUrl}
					/>
				</div>
			</div>

			<h3 className="my-4 text-xs uppercase">{_.tabHelpTitle}</h3>

			<div className="flex">
				<div className="flex-1">
					<NavLink
						small
						title={_.sectionFaqTitle}
						href={_.linkFaqMoreUrl}
					/>
					<NavLink small title={_.tabContactTitle} />
				</div>
				<div className="flex-1">
					<NavLink small title={_.linkChatTitle} />
				</div>
			</div>

			<h3 className="my-4 text-xs uppercase">{_.tabJoinTitle}</h3>
			<div className="-m-4 mt-4 rounded-b bg-blue-50">
				<div className="p-4">
					<h4 className="mb-3 font-bold">{_.linkJoinHeader}</h4>
					<NavLink
						small
						title={_.linkJoinPrivateTitle}
						href={_.linkJoinPrivateUrl}
					/>
					<NavLink
						small
						title={_.linkJoinCorporateTitle}
						href={_.linkJoinCorporateUrl}
					/>
					<NavLink
						small
						title={_.linkJoinAddTitle}
						href={_.linkJoinAddUrl}
					/>
				</div>
				<div className="p-4">
					<h4 className="mb-2 font-bold">{_.linkJoinHeaderFXP}</h4>
					<NavLink
						small
						title={_.linkJoinPrivateTitleFXP}
						href={_.linkJoinPrivateUrlFXP}
					/>
					<NavLink
						small
						title={_.linkJoinCorporateTitleFXP}
						href={_.linkJoinCorporateUrlFXP}
					/>
				</div>
				<div className="rounded-b bg-blue-100 p-4">
					<h4 className="mb-2 font-bold">{_.linkJoinHeaderEU}</h4>
					<NavLink
						small
						title={_.linkJoinPrivateTitleFXP}
						href={_.linkJoinPrivateUrlEU}
					/>
					<NavLink
						small
						title={_.linkJoinCorporateTitleFXP}
						href={_.linkJoinCorporateUrlEU}
					/>
				</div>
			</div>
		</motion.div>
	);
};

const NavMobile: React.FC<React.HTMLAttributes<HTMLElement>> = ({
	className,
}) => {
	const [open, setOpen] = useState(false);
	const motionMenuBg = {
		hidden: { opacity: 0 },
		visible: {
			opacity: open ? 1 : 0,
			scale: 0.95,
			x: open ? 0 : -18,
			y: open ? 0 : 14,
		},
	};
	return (
		<nav className={cx("relative w-64 min-w-full", className)}>
			<div className="flex items-center">
				<a href="/">
					<Logo className="logo my-4 ml-5 w-28" />
				</a>
			</div>
			<motion.div
				layout
				layoutId="menuBg"
				initial={"hidden"}
				animate={"visible"}
				exit={"hidden"}
				variants={motionMenuBg}
				className={cx(
					"absolute right-0 top-0 origin-top-right bg-white p-4 text-sm shadow",
					open ? "w-full rounded" : "pointer-events-none"
				)}
			>
				{open && <NavMenu />}
			</motion.div>
			<motion.div>
				{open ? (
					<motion.div className="absolute right-5 top-5 cursor-pointer text-gray-700">
						<Icon
							name="x"
							className="h-5"
							onMouseUp={() => setOpen(false)}
						/>
					</motion.div>
				) : (
					<motion.div className="absolute right-5 top-5 cursor-pointer">
						<Icon
							name="menu"
							className="h-6"
							onMouseUp={() => setOpen(true)}
						/>
					</motion.div>
				)}
			</motion.div>
		</nav>
	);
};

export default NavMobile;
