import { motion } from "framer-motion";
import React from "react";
import { useLocale } from "./LocaleProvider";
import NavLink from "./NavLink";
import Icon from "../lib/icons";

type FooterLinkProps = {
	href: string;
	title: string;
	description: string;
};

const FooterLink: React.FC<FooterLinkProps> = ({
	href,
	title,
	description,
}) => {
	return (
		<a href={href} className="group mb-4 block last:mb-0">
			<h4 className="font-semibold text-blue-100 transition-colors group-hover:text-white">
				{title}
			</h4>
			<p className="text-blue-300 transition-colors group-hover:text-white">
				{description}
			</p>
		</a>
	);
};

const NavJoin: React.FC = () => {
	const l = useLocale().keys;
	const motionContainer = {
		hidden: { opacity: 0, y: -10 },
		visible: { opacity: 1, y: 0 },
	};
	const motionFooter = {
		hidden: { opacity: 0, y: 100 },
		visible: {
			opacity: 1,
			y: 0,
			transition: {
				delay: 0.25,
				type: "spring",
				duration: 0.3,
				bounce: 0.25,
			},
		},
	};
	return (
		<>
			<motion.div
				className="bg-blue-500 text-white"
				variants={motionContainer}
			>
				<div className="p-4">
					<h4 className="mb-3 flex items-center">
						<Icon name="globe" className="h-4 w-4" />
						<span className="font-bold">{l.linkJoinHeader}</span>
					</h4>
					<NavLink
						light
						icon="home"
						title={l.linkJoinPrivateTitle}
						href={l.linkJoinPrivateUrl}
						className="sm:font-normal"
					/>
					<div className="h-2" />
					<NavLink
						light
						icon="briefcase"
						title={l.linkJoinCorporateTitle}
						href={l.linkJoinCorporateUrl}
						className="sm:font-normal"
					/>
				</div>
				<div className="p-4">
					<h4 className="mb-2 ml-5 font-bold">
						{l.linkJoinHeaderFXP}
					</h4>
					<NavLink
						light
						icon="home"
						title={l.linkJoinPrivateTitleFXP}
						href={l.linkJoinPrivateUrlFXP}
						className="sm:font-normal"
					/>
					<div className="h-2" />
					<NavLink
						light
						icon="briefcase"
						title={l.linkJoinCorporateTitleFXP}
						href={l.linkJoinCorporateUrlFXP}
						className="sm:font-normal"
					/>
				</div>
				<div className="bg-blue-600 p-4">
					<h4 className="mb-3 flex items-center">
						<Icon name="euro" className="h-4 w-4" />
						<span className="font-bold">{l.linkJoinHeaderEU}</span>
					</h4>
					<NavLink
						light
						icon="home"
						title={l.linkJoinPrivateTitle}
						href={l.linkJoinPrivateUrlEU}
						className="sm:font-normal"
					/>
					<div className="h-2" />
					<NavLink
						light
						icon="briefcase"
						title={l.linkJoinCorporateTitle}
						href={l.linkJoinCorporateUrlEU}
						className="sm:font-normal"
					/>
				</div>
				<motion.div
					className="bg-blue-800 p-4 text-xs"
					variants={motionFooter}
				>
					<FooterLink
						title={l.linkJoinAddTitle}
						description={l.linkJoinAddDescription}
						href={l.linkJoinAddUrl}
					/>
					<FooterLink
						title={l.linkJoinExtraTitle}
						description={l.linkJoinExtraDescription}
						href={l.linkJoinExtraUrl}
					/>
				</motion.div>
			</motion.div>
		</>
	);
};

export default NavJoin;
