import { motion } from "framer-motion";
import React from "react";
import { useLocale } from "./LocaleProvider";
import NavLink from "./NavLink";

const NavServices: React.FC = () => {
	const l = useLocale().keys;
	const motionFooter = {
		hidden: { opacity: 0, y: 100 },
		visible: {
			opacity: 1,
			y: 0,
			transition: {
				delay: 0.1,
				type: "spring",
				duration: 0.3,
				bounce: 0.25,
			},
		},
	};
	return (
		<>
			<motion.div className="flex-column flex max-w-lg bg-white">
				<div className="relative flex-1 bg-gradient-to-b from-white via-gray-50 to-white p-4">
					<NavLink
						icon="home"
						title={l.linkPrivateTitle}
						description={l.linkPrivateDescription}
						href={l.linkPrivateUrl}
					/>
					<NavLink
						icon="briefcase"
						title={l.linkCorporateTitle}
						description={l.linkCorporateDescription}
						href={l.linkCorporateUrl}
					/>
					<NavLink
						icon="library"
						title={l.linkInstitutionalTitle}
						description={l.linkInstitutionalDescription}
						href={l.linkInstitutionalUrl}
					/>
				</div>
				<div className="my-8 border-l" />
				<div className="flex flex-1 flex-col justify-center p-4">
					<NavLink
						icon="chevronRight"
						title={l.linkMobileAppTitle}
						description={l.linkMobileAppDescription}
						href={l.linkMobileAppUrl}
					/>
					<NavLink
						icon="chevronRight"
						title={l.linkDailyReportTitle}
						description={l.linkDailyReportDescription1}
						href={l.linkDailyReportUrl}
					/>
					<NavLink
						title={l.linkTradewaveTitle}
						icon="chevronRight"
						description={l.linkTradewaveDescription}
						href={l.linkTradewaveUrl}
					/>
				</div>
			</motion.div>
			<motion.div
				className="flex-column flex max-w-lg bg-blue-50 text-xs"
				variants={motionFooter}
			>
				<div className="flex-1 p-4">
					<NavLink
						small
						icon="chevronRight"
						title={l.linkHowItWorksTitle}
						description={l.linkHowItWorksDescription}
						href={l.linkHowItWorksUrl}
					/>
				</div>
				<div className="my-4 border-l border-blue-100" />
				<div className="flex-1 p-4">
					<NavLink
						small
						icon="chevronRight"
						title={l.linkFreeQuoteTitle}
						description={l.linkFreeQuoteDescription}
						href={l.linkFreeQuoteUrl}
					/>
				</div>
			</motion.div>
		</>
	);
};

export default NavServices;
