import cx from "classnames";
import React, { useState } from "react";
import Icon, { IconName } from "../lib/icons";

type Props = {
	title?: string;
	href?: string;
	icon?: IconName;
	description?: string;
	small?: boolean;
	light?: boolean;
	className?: string;
};
const NavLink: React.FC<Props> = ({
	title,
	href,
	icon = "chevronRight",
	description,
	small,
	light,
	className,
}) => {
	const [hover, setHover] = useState(false);
	const isExternal = href?.startsWith("http");
	const jsAction = href?.startsWith("javascript:")
		? href?.replace("javascript:", "")
		: undefined;
	return (
		<a
			href={jsAction ? "#" : href}
			onClick={() => jsAction && eval(jsAction)}
			target={isExternal ? "_blank" : "_self"}
			rel={"noreferrer"}
			className={cx(
				"group block font-semibold last:mb-0",
				description ? "mb-5" : "mb-2",
				className
			)}
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
		>
			<h4
				className={cx(
					small && "text-gray-800",
					"ml-5 transition-colors",
					light
						? "group-hover:text-white"
						: "group-hover:text-blue-400"
				)}
			>
				<Icon
					name={hover ? "chevronRight" : icon}
					className={cx(
						!small && "md:h-4",
						"-ml-5 transform transition-transform group-hover:translate-x-1"
					)}
				/>
				<span className="align-middle">{title}</span>
			</h4>
			{description && (
				<p
					className={cx(
						"ml-5 font-normal",
						small ? "mt-1 text-gray-500" : "mt-2 text-gray-400"
					)}
				>
					{description}
				</p>
			)}
		</a>
	);
};

export default NavLink;
